<template>
    <div class="self-extract-container">
        <div  v-if="authList.indexOf(4)>-1">
            <!--头部分-->
            <div class="head">
                <div class="head-date">
                    <div class="date-ranger-container" style="padding-top: 10px;">
                        <div>
                            <search-v2
                                    class="doneOrderList_searchBox_item header-search"
                                    v-model="keyword"
                                    @search="search"
                                    @clear="search"
                                    placeholder="请输入自提点名称"


                            ></search-v2>
                        </div>
                        <div style="margin-top: 10px">
                            <date-ranger-v1 :orginDate="date" @chooseDate="chooseDate"></date-ranger-v1>
                        </div>

                    </div>
                    <div class="head-tj-sum">
                        <div class="head-tj-sum-son">
                            <div><img style="width:18px; vertical-align:middle;" :src="require('@/static/image/icons/money-sleft.png')">平台销量</div>
                            <div>￥{{totalMoney}}</div>
                        </div>
                        <div class="head-tj-sum-line"></div>
                        <div class="head-tj-sum-son">
                            <div><img style="width:18px; vertical-align:middle;" :src="require('@/static/image/icons/money-sright.png')">平台利润</div>
                            <div>￥{{totalProfit}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="search-body">
                <div v-if="PointsList.length>0" class="search-body-son" v-for="(item, index) in PointsList" :key="index">
                    <div class="search-body-son-select" @click="changeSon(item.id)">
                        <div v-if="checkList.indexOf(item.id)==-1"  style="width: 21px;margin:auto; height: 21px;border: 1px solid #BFBFBF;border-radius:50%"></div>
                        <img v-else style="width:22px; vertical-align:middle;" :src="require('@/static/image/icons/tj-selected.png')">
                    </div>
                    <div class="search-body-son-result">
                        <div class="search-body-son-result-title" @click="showStoresList(index)">
                            {{item.site_name}}
                            <img v-if="storeIndex==index && show==true" class="search-body-son-tip" style="vertical-align:middle;" :src="require('@/static/image/icons/r-up.png')">
                            <img v-else class="search-body-son-tip" style="vertical-align:middle;" :src="require('@/static/image/icons/r-down.png')">
                        </div>
                        <div v-if="storeIndex==index && show==true"  v-for="(son,sonIndex) in item.list" class="search-body-son-result-list">{{son.name}}：{{son.total}}</div>

                        <div class="search-body-son-result-line"></div>
                        <div class="search-body-son-result-sum">•总获得佣金：￥{{item.withMoney.pointTotalMoney}}</div>
                        <div class="search-body-son-result-sum" style="padding-bottom: 10px">•可提现金额：￥{{item.withMoney.canWith}}</div>
                    </div>
                </div>

            </div>


            <div class="search-footer">
                <div class="search-footer-left" @click="checkAllSon">
                    <div v-if="checkAll==false" style="width: 21px;height: 21px;border: 1px solid #BFBFBF;border-radius:50%"></div>
                    <img v-else style="width:22px; vertical-align:middle;" :src="require('@/static/image/icons/tj-selected.png')">
                    <div style="padding-left: 10px">全选</div>
                </div>
                <div class="search-footer-right">
                    <div></div>
                    <div @click="search">筛选</div>
                </div>
            </div>

        </div>
        <div v-else style="text-align: center;padding-top:100px;">

        </div>

    </div>

</template>

<script>
    import DateRangerV1 from "../../template/community/date/dateRangerV1";
    import SearchV2 from "../../template/community/search/searchV2";
    export default {
        name: 'money-statistics',
        components: {DateRangerV1,SearchV2},
        created () {
            this.getYearDate()
            this.myCenterInfo()
            this.platformProfitByPoint()
        },
        data () {
            return {
                date:[], //搜索时间
                totalMoney:0,
                totalProfit:0,
                PointsList:[],
                show:false, //默认不显示
                storeIndex:-1, //显示的位置
                keyword:'',
                checkList:[], //选中的id
                checkAll:false, //全选按钮
                authList:[], //权限列表
            }
        },

        methods: {
            async myCenterInfo () {

                try {
                    let query = await this.$api.community.points.getStatisticsAuth()
                    let getAuthList=query.data
                    if (getAuthList.length>0){
                        getAuthList.forEach((item, index)=>{
                            this.authList.push(item.auth_id)
                        })
                    }
                    console.log('getStatisticsAuth success', this.authList)
                } catch (error) {
                    console.log('dataInfo error', error)
                }
            },
            checkAllSon(){
                this.checkAll=!this.checkAll;

                //全选按钮 首先判断当前站点存在
                if (this.PointsList.length > 0) {

                    this.checkList=[] //清空子集状态

                    if (this.checkAll){ //如果当前是全选状态

                        this.PointsList.forEach((item) => {
                            this.checkList.push(item.id)
                        })

                    }
                    //console.log("all",this.checkList)

                }
            },

            changeSon(pointId){
                let ckIndex=this.checkList.indexOf(pointId)
                if (ckIndex==-1){ //选中状态
                    this.checkList.push(pointId)
                    if (this.checkList.length==this.PointsList.length){
                        this.checkAll=true
                    }
                }else{ //取消选中状态
                    this.checkList.splice(ckIndex,1)
                    if (this.checkList.length<this.PointsList.length){
                        this.checkAll=false
                    }
                }
                //console.log(this.checkList,pointId)

            },


            search () {
                this.platformProfitByPoint()

            },
            showStoresList(index){

                if (index==this.storeIndex){
                    this.show=!this.show
                }else{
                    this.storeIndex=index
                    this.show=true
                }

            },
            getYearDate: function () {

                var today = new Date();
                var year = today.getFullYear();
                var month = today.getMonth() + 1;
                var day = today.getDate();

                var laskTime = today.getTime() - 7 * 24 * 60 * 60 * 1000;
                var tragetTime = new Date(laskTime);
                var tragetYear = tragetTime.getFullYear();
                var tragetMonth = tragetTime.getMonth() + 1;
                var tragetDay = tragetTime.getDate();
                this.date = tragetYear + '-' + tragetMonth + '-' + tragetDay + ',' + year + '-' + month + '-' + day

            },

            //通过自提点获取利润数据
            async platformProfitByPoint() {
                this.toast = this.$toast.loading("数据加载中...");
                try {
                    let params={
                        point_ids:this.checkList,
                        point_keyword:this.keyword,
                        date:this.date,

                    }
                    let query = await this.$api.community.points.platformProfitByPoint(params)

                    this.totalMoney = query.data.totalMoney
                    this.totalProfit = query.data.totalProfit
                    this.PointsList = query.data.pointArr
                    this.toast.hide()
                } catch (error) {
                    this.toast.hide()
                }

            },

            //时间搜索
            chooseDate(date) {
                this.date=date.toString();
                this.platformProfitByPoint()
            },
        }
    }
</script>

<style scoped lang="scss">
    div{
        border:0px solid red;
    }
    .self-extract-container {
        background:#F8F8F8;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        padding-bottom: 100px;

        .head{
            width: 100%;
            background:#FFFFFF;

            .head-date{
                width:345px;
                margin: auto;
            }

            .head-tj-sum{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:10px 0 10px 0;
                .head-tj-sum-son{
                    border:0px solid red;
                    width:45%;

                    div:nth-child(1){
                        height: 30px;
                        border:0px solid red;
                        padding-left:15px;
                        font-size: 12px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #303030;
                        line-height: 30px;
                    }
                    div:nth-child(2){
                        height: 30px;
                        border:0px solid red;
                        padding-left:30px;
                        font-size: 12px;
                        font-family: PingFang-SC-Bold, PingFang-SC;
                        font-weight: bold;
                        color: #F46A17;
                        line-height: 30px
                    }
                }
                .head-tj-sum-line{
                    border:0px solid red;
                    width: 1px;
                    height: 20px;
                    background: #D8D8D8;
                }

            }

        }
        .search-body{
            width: 100%;
            background:#FFFFFF;
            margin-top: 8px;
            padding-bottom: 10px;


            .search-body-son{
                display:flex;
                padding:10px 0;
                .search-body-son-select{
                    width: 51px;
                    border:0px solid red;
                    text-align: center;
                    padding-top: 35px;
                }

                .search-body-son-result{
                    width: 300px;

                    background: rgba(244, 106, 23, 0.1);
                    border-radius: 6px;
                    padding:0 15px 0 15px;
                    margin-right: 10px;

                    .search-body-son-result-title{
                        border:0px solid red;
                        height: 39px;
                        line-height:39px;
                        font-size: 14px;
                        font-family: PingFang-SC-Bold, PingFang-SC;
                        font-weight: bold;
                        color: #303030;
                        align-items: center;
                        display:flex;
                        justify-content:space-between;
                        align-items: center;

                    }
                    .search-body-son-tip{

                        width:10px;

                    }

                    .search-body-son-result-list{
                        height: 24px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 24px;
                        padding-left: 10px;
                    }
                    .search-body-son-result-line{
                        height: 1px;
                        background: #F46A17;
                        opacity: 0.2;
                    }
                    .search-body-son-result-sum{
                        font-size: 12px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #E40000;
                        line-height: 24px;
                        height: 24px;
                        border: 0px solid red;
                        padding-left: 10px;
                    }
                }
            }

        }


        .search-footer{
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 0.5);
            position: fixed;
            bottom: 10px;
            display:flex;

            .search-footer-left{
                border:0px solid red;
                height: 100%;
                width:50%;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #303030;
                line-height: 44px;
                padding-left: 10px;
                display: flex;
                align-items: center;

            }
            .search-footer-right{
                border:0px solid red;
                height: 100%;
                width:50%;
                display: flex;
                justify-content: space-between;
                text-align: center;
                line-height:44px;
                font-size:14px;
                div:nth-child(1){
                    width:50%;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #979797;

                }
                div:nth-child(2){
                    width:50%;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    background: #F46A17;
                    border-radius: 0px 50px 50px 0px
                }
            }

        }




    }


</style>
